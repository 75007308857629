import { useState, useEffect } from 'react';
import { Flex, Button, Card, DropZone, Text, ScrollView } from '@aws-amplify/ui-react';
import { BsCpu, BsXOctagonFill } from "react-icons/bs";

import { FileSelector } from '../common/fileSelector';
import { HeightSlider, IsOutOfRange } from '../common/heightSlider';
import { StatusLine, UpdateState } from '../common/statusline';
import { ErrorCard, ShowError } from '../common/errorCard';
import { ResultCard } from './resultsCard';
import { Sleep, ChangeVisibilityById, SendRequest, GetPresignedUrl, UploadFile,
         IsMobile } from '../common/utils';

import '@aws-amplify/ui-react/styles.css';
import './main.css';

const ENDPOINT = "/installation_verifier_qr";

function ResetResultsData(setResultsData) {
    setResultsData({
                   facilityCode: "unknown",
                   projectId: "unknown",
                   spaceId: "unknown",
                   deviceId: "unknown",
                   correspondenceStatus: "not checked",
                   placementHeight: "not measured",
                   fovStatus: "not checked",
                   completionPercentage: 0,
                   deviceStatus: "not processed"
    });
}

function PostResponse(response, setStatus) {
    if (200 === response.status) {
        setStatus("success");
    } else {
        console.log(response.data.msg);
        setStatus("error");
    }
}

async function ProcessDevice(file, setDevicePercentage, setDeviceText, setDeviceStatus) {
    document.getElementById("deviceBadge").classList.add("active");
    document.getElementById("contextBadge").classList.add("active");
    document.getElementById("additionalBadge").classList.remove("active");
    document.getElementById("doneBadge").classList.remove("active");
    let stepInfo = { "previous" : 0, "current" : 0 };
    let response;
    stepInfo["current"] = 20;
    await UpdateState("Uploading", 300, stepInfo, setDevicePercentage, setDeviceText);
    const formData = new FormData();
    formData.append("file_name", file.name);
    response = await GetPresignedUrl(formData, ENDPOINT + "/get_presigned_url");
    if (200 !== response.status) {
        stepInfo["current"] = 100;
        await UpdateState("Failure", 100, stepInfo, setDevicePercentage, setDeviceText);
        PostResponse(response, setDeviceStatus);
        return response;
    }
    stepInfo["current"] = 80;
    await UpdateState("Processing", 300, stepInfo, setDevicePercentage, setDeviceText);
    response = await UploadFile(file, response.data.url);
    if (200 !== response.status) {
        stepInfo["current"] = 100;
        await UpdateState("Failure", 100, stepInfo, setDevicePercentage, setDeviceText);
        PostResponse(response, setDeviceStatus);
        return response;
    }
    stepInfo["current"] = 90;
    await UpdateState("Retrieving Results", 300, stepInfo, setDevicePercentage, setDeviceText);
    response = await SendRequest({ method: "post",
                                   endpoint: ENDPOINT + "/process_device",
                                   data: formData });
    if (200 !== response.status) {
        stepInfo["current"] = 100;
        await UpdateState("Failure", 100, stepInfo, setDevicePercentage, setDeviceText);
        PostResponse(response, setDeviceStatus);
        return response;
    }
    stepInfo["current"] = 100;
    await UpdateState("Done", 100, stepInfo, setDevicePercentage, setDeviceText);
    PostResponse(response, setDeviceStatus);
    return response;
}

async function ProcessContext(file, setContextPercentage, setContextText, setContextStatus) {
    document.getElementById("deviceBadge").classList.remove("active");
    document.getElementById("contextBadge").classList.add("active");
    document.getElementById("additionalBadge").classList.add("active");
    document.getElementById("doneBadge").classList.remove("active");
    let stepInfo = { "previous" : 0, "current" : 0 };
    stepInfo["current"] = 20;
    await UpdateState("Uploading", 300, stepInfo, setContextPercentage, setContextText);
    stepInfo["current"] = 80;
    await UpdateState("Processing", 500, stepInfo, setContextPercentage, setContextText);
    stepInfo["current"] = 90;
    await UpdateState("Retrieving Results", 100, stepInfo, setContextPercentage, setContextText);
    stepInfo["current"] = 100;
    await UpdateState("Done", 100, stepInfo, setContextPercentage, setContextText);
    setContextStatus("success");
    let response;
    response = {
        status: 200,
        data: {
            correspondenceStatus: "correct",
        }
     };
    setContextStatus(200 === response.status ? "success" : "error");
    return response;
}

async function ProcessAdditional(resultsData, setAdditionalPercentage, setAdditionalText,
                                 setAdditionalStatus) {
    document.getElementById("deviceBadge").classList.remove("active");
    document.getElementById("contextBadge").classList.remove("active");
    document.getElementById("additionalBadge").classList.add("active");
    document.getElementById("doneBadge").classList.add("active");
    let stepInfo = { "previous" : 0, "current" : 0 };
    let response;
    stepInfo["current"] = 80;
    await UpdateState("Processing", 300, stepInfo, setAdditionalPercentage, setAdditionalText);
    const formData = new FormData();
    formData.append("facility_code", resultsData.facilityCode);
    formData.append("project_id", resultsData.projectId);
    formData.append("device_id", resultsData.deviceId);
    formData.append("height", resultsData.placementHeight);
    formData.append("is_out_of_range", IsOutOfRange);
    response = await SendRequest({ method: "post",
                                   endpoint: ENDPOINT + "/set_height",
                                   data: formData });
    if (200 !== response.status) {
        stepInfo["current"] = 100;
        await UpdateState("Failure", 100, stepInfo, setAdditionalPercentage, setAdditionalText);
        PostResponse(response, setAdditionalStatus);
        return response;
    }
    stepInfo["current"] = 90;
    await UpdateState("Retrieving Results", 100, stepInfo, setAdditionalPercentage, setAdditionalText);
    stepInfo["current"] = 100;
    await UpdateState("Done", 100, stepInfo, setAdditionalPercentage, setAdditionalText);
    PostResponse(response, setAdditionalStatus);
    return response;
}

async function ProcessFile(file, setDevicePercentage, setDeviceText, setDeviceStatus,
                           setContextPercentage, setContextText, setContextStatus,
                           setAdditionalPercentage, setAdditionalText, setAdditionalStatus,
                           setResultsData, resultsData, setMessage, setDisableBrowse, setFile,
                           setProcessPhase, processPhase) {
    let message;
    let response;
    ChangeVisibilityById("cancelButton", "none");
    ChangeVisibilityById("errorCard", "none");
    switch (processPhase) {
        case "device":
            ChangeVisibilityById("resultsCard", "none");
            setDeviceStatus("default");
            setContextStatus("default");
            setAdditionalStatus("default");
            await Sleep(100);
            response = await ProcessDevice(file, setDevicePercentage, setDeviceText, setDeviceStatus);
            resultsData["image"] = response.data.file_name;
            if ("device_status" in response.data) {
                resultsData["facilityCode"] = response.data.facility_code;
                resultsData["projectId"] = response.data.project_id;
                resultsData["spaceId"] = response.data.space_id;
                resultsData["deviceId"] = response.data.device_id;
                resultsData["deviceStatus"] = response.data.device_status;
                resultsData["architecturalDrawing"] = response.data.architectural_drawing;
                resultsData["completionPercentage"] = response.data.completion_percentage;
            }
            if (200 !== response.status) {
                message = response.data.msg;
                document.getElementById("contextBadge").classList.remove("active");
            } else {
                setProcessPhase("context");
            }
            break;
        case "context":
            response = await ProcessContext(file, setContextPercentage, setContextText,
                                            setContextStatus);
            resultsData["context"] = response.data.file_name
            if (200 !== response.status) {
                message = response.data.msg;
                document.getElementById("additionalBadge").classList.remove("active");
            } else {
                resultsData["correspondenceStatus"] = response.data.correspondenceStatus;
                setProcessPhase("additional");
            }
            break;
        case "additional":
            response = await ProcessAdditional(resultsData, setAdditionalPercentage, setAdditionalText,
                                               setAdditionalStatus);
            if (200 !== response.status) {
                message = response.data.msg;
                document.getElementById("doneBadge").classList.remove("active");
            } else {
                resultsData["fovStatus"] = "clear";
                setProcessPhase("final");
            }
            break;
        default:
            break;
    }
    resultsData["isFailure"] = false;
    if (message) {
        resultsData["isFailure"] = true;
        resultsData["faillureReason"] = response.data.msg;
        ShowError(setMessage, message);
        setDisableBrowse(false);
        if ("device" !== processPhase || ("device" === processPhase && resultsData.deviceStatus.startsWith("DUPLICATE"))) {
            ChangeVisibilityById("cancelButton", "block");
        }
    }
    setFile(null);
    setResultsData(resultsData);
}

function HintText({ processPhase }) {
    if (["final", "device"].includes(processPhase)) {
        return (<>containing <span id="hintKeyWord">Device QR code</span></>);
    } else if ("context" === processPhase) {
        return (<>containing <span id="hintKeyWord">Device QR code taken from far</span></>);
    } else if ("additional" === processPhase) {
        return (<>for <span id="hintKeyWord">placement height</span> measurement</>);
    }
}

function InputField({ setFile, disableBrowse, setDisableBrowse, setMessage, processPhase }) {
    const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    const [compressionPercentage, setCompressionPercentage] = useState(100);
    useEffect(() => {
        ChangeVisibilityById("compressionStatus",
                             100 === compressionPercentage ? "none" : "block");
    }, [compressionPercentage]);
    const OnFileDrop = (files) => {
        let acceptedFiles = files.acceptedFiles;
        if (0 === acceptedFiles.length) {
            return;
        }
        setFile(acceptedFiles[0]);
    };
    return (
        <div>
            { IsMobile ?
                <Flex id="captureZone"
                      direction="column"
                      alignItems="center">
                    <Text>Capture image <HintText processPhase={processPhase} /></Text>
                    <FileSelector setFile={setFile}
                                  disableBrowse={disableBrowse}
                                  setDisableBrowse={setDisableBrowse}
                                  setMessage={setMessage}
                                  setCompressionPercentage={setCompressionPercentage}
                                  acceptedFileTypes={acceptedFileTypes}
                                  isMultiple={false} />
                    <Text id="compressionStatus">Compressing Image: {compressionPercentage}%</Text>
                </Flex>
            :
                <DropZone id="dropZone"
                          acceptedFileTypes={acceptedFileTypes}
                          onDropComplete={OnFileDrop}>
                    <Flex direction="column"
                          alignItems="center">
                        <Text>Drop image <HintText processPhase={processPhase} /> here or</Text>
                        <FileSelector setFile={setFile}
                                      disableBrowse={disableBrowse}
                                      setDisableBrowse={setDisableBrowse}
                                      setMessage={setMessage}
                                      setCompressionPercentage={setCompressionPercentage}
                                      acceptedFileTypes={acceptedFileTypes}
                                      isMultiple={false} />
                    </Flex>
                    <Text id="compressionStatus">Compressing Image: {compressionPercentage}%</Text>
                </DropZone>
            }
        </div>
    );
}

async function CancelOnClick(setFile, setDisableBrowse, setDisableProcess, resultsData) {
    ChangeVisibilityById("cancelButton", "none");
    ChangeVisibilityById("errorCard", "none");
    ChangeVisibilityById("resultsCard", "block");
    setDisableBrowse(true);
    setDisableProcess(true);
    const formData = new FormData();
    formData.append("facility_code", resultsData.facilityCode);
    formData.append("project_id", resultsData.projectId);
    formData.append("device_status", resultsData.deviceStatus);
    formData.append("file_name", resultsData.image);
    formData.append("device_id", resultsData.deviceId);
    let response = await SendRequest({ method: "post",
                                       endpoint: ENDPOINT + "/cancel_process",
                                       data: formData });
    if (null === response || 200 !== response.status) {
        console.log(response.data.msg);
    }
    setFile(null);
    ChangeVisibilityById("resultsCard", "block");
}

function UploadCard({ file, setFile, setDisableToggle, disableBrowse, setDisableBrowse,
                      processPhase, setProcessPhase, setDevicePercentage, setDeviceText,
                      setDeviceStatus, setContextPercentage, setContextText,
                      setContextStatus, setAdditionalPercentage, setAdditionalText,
                      setAdditionalStatus, doneStatus, setResultsData, resultsData,
                      setMessage }) {
    const [disableProcess, setDisableProcess] = useState(true);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        setDisableProcess(null === file);
    }, [file]);
    useEffect(() => {
        if ("success" === doneStatus) {
            ChangeVisibilityById("resultsCard", "block");
            ChangeVisibilityById("fovField",
                                 RegExp("^CAM").test(resultsData.deviceId) ? "block" : "none");
            ChangeVisibilityById("placementField",
                                 RegExp("^(CAM|ACD)").test(resultsData.deviceId) ? "block" : "none");
        }
    }, [doneStatus]);
    const processClicked = () => {
        setDisableBrowse(true);
        setDisableProcess(true);
        setDisableToggle(true);
        ProcessFile(file, setDevicePercentage, setDeviceText, setDeviceStatus,
                    setContextPercentage, setContextText, setContextStatus,
                    setAdditionalPercentage, setAdditionalText, setAdditionalStatus,
                    setResultsData, resultsData, setMessage, setDisableBrowse,
                    setFile, setProcessPhase, processPhase);
    };
    useEffect(() => {
        switch (processPhase) {
            case "device":
                ResetResultsData(setResultsData);
                break;
            case "additional":
                setDisableProcess(false);
                break;
            case "final":
                setDisableBrowse(true);
                setDisableToggle(false);
                break;
            default:
                setDisableBrowse(false);
                setDisableToggle(false);
                break;
        }
    }, [processPhase]);
    useEffect(() => {
        let button = document.getElementById("processButton");
        if (IsOutOfRange && "additional" === processPhase) {
            button.classList.add("error");
        } else {
            button.classList.remove("error");
        }
    }, [IsOutOfRange, processPhase]);
    return (
        <Card variation="default"
              width={{ base: "90%", large: "30%" }}>
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="1rem">
                { "additional" === processPhase ?
                    <HeightSlider height={height}
                                  disableField={disableProcess}
                                  setHeight={setHeight}
                                  resultsData={resultsData} />
                :
                    <InputField setFile={setFile}
                                disableBrowse={disableBrowse}
                                setDisableBrowse={setDisableBrowse}
                                setMessage={setMessage}
                                processPhase={processPhase} />
                }
                { null !== file ?
                <ScrollView id="filesView">
                    <Text key={file.name}>{file.name}</Text>
                </ScrollView>
                :
                    null
                }
                <Flex direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem">
                    { IsOutOfRange && "additional" === processPhase ?
                        <Button id="processButton"
                                onClick={processClicked}
                                isDisabled={disableProcess}
                                variation="primary"
                                colorTheme="error"
                                gap="0.2rem" >
                            <BsCpu />Confirm
                        </Button>
                        :
                        <Button id="processButton"
                                onClick={processClicked}
                                isDisabled={disableProcess}
                                variation="primary"
                                colorTheme="success"
                                gap="0.2rem" >
                            <BsCpu />Process
                        </Button>
                    }
                    <Button id="cancelButton"
                            onClick={() => { CancelOnClick(setFile, setDisableBrowse, setDisableProcess,
                                                           resultsData); }}
                            variation="primary"
                            colorTheme="error"
                            gap="0.2rem" >
                        <BsXOctagonFill /> Cancel
                    </Button>
                </Flex>
            </Flex>
        </Card>
    );
}

export const QrApp = ({ disableBrowse, setDisableBrowse, setDisableToggle, setMessage,
                        message }) => {
    const [file, setFile] = useState(null);
    const [devicePercentage, setDevicePercentage] = useState(0);
    const [deviceText, setDeviceText] = useState("");
    const [deviceStatus, setDeviceStatus] = useState("default");
    const [contextPercentage, setContextPercentage] = useState(0);
    const [contextText, setContextText] = useState("");
    const [contextStatus, setContextStatus] = useState("default");
    const [additionalPercentage, setAdditionalPercentage] = useState(0);
    const [additionalText, setAdditionalText] = useState("");
    const [additionalStatus, setAdditionalStatus] = useState("default");
    const [doneStatus, setDoneStatus] = useState("defualt");
    const [processPhase, setProcessPhase] = useState("");
    const [resultsData, setResultsData] = useState({});
    useEffect(() => {
        ResetResultsData(setResultsData);
        setProcessPhase("device");
    }, []);
    return (
        <>
            <UploadCard file={file}
                        setFile={setFile}
                        setDisableToggle={setDisableToggle}
                        disableBrowse={disableBrowse}
                        setDisableBrowse={setDisableBrowse}
                        processPhase={processPhase}
                        setProcessPhase={setProcessPhase}
                        setDevicePercentage={setDevicePercentage}
                        setDeviceText={setDeviceText}
                        setDeviceStatus={setDeviceStatus}
                        setContextPercentage={setContextPercentage}
                        setContextText={setContextText}
                        setContextStatus={setContextStatus}
                        setAdditionalPercentage={setAdditionalPercentage}
                        setAdditionalText={setAdditionalText}
                        setAdditionalStatus={setAdditionalStatus}
                        doneStatus={doneStatus}
                        setResultsData={setResultsData}
                        resultsData={resultsData}
                        setMessage={setMessage} />
            <StatusLine mode="qr"
                        devicePercentage={devicePercentage}
                        deviceText={deviceText}
                        deviceStatus={deviceStatus}
                        contextPercentage={contextPercentage}
                        contextText={contextText}
                        contextStatus={contextStatus}
                        additionalPercentage={additionalPercentage}
                        additionalText={additionalText}
                        additionalStatus={additionalStatus}
                        doneStatus={doneStatus}
                        setDoneStatus={setDoneStatus} />
            <ErrorCard message={message} />
            <ResultCard resultsData={resultsData}
                        setFile={setFile}
                        setDisableBrowse={setDisableBrowse}
                        setDisableToggle={setDisableToggle}
                        setProcessPhase={setProcessPhase}
                        setDeviceStatus={setDeviceStatus}
                        setContextStatus={setContextStatus}
                        setAdditionalStatus={setAdditionalStatus} />
        </>
    );
}
