import { Flex, Card, Button, Text, Divider, Link } from '@aws-amplify/ui-react';
import { BsCheckLg } from "react-icons/bs";

import { ChangeVisibilityById } from '../../common/utils';

import './resultsCard.css';

function GetObjectsCount(data) {
    return null === data || undefined === data ? 0 : Object.keys(data).length;
}

function DoneProcess(setFiles, setDisableBrowse, setDisableToggle, setIsProcessing) {
    setFiles([]);
    setIsProcessing(false);
    setDisableBrowse(false);
    setDisableToggle(false);
    ChangeVisibilityById("resultsCard", "none");
}

function ValidSection({ resultsData }) {
    return (
        <>
            <Divider orientation="horizontal"
                     size="small" />
            {GetObjectsCount(resultsData.verifiedTag) ?
                <Text>
                    <span id="resultField">Number of verified TAGs(Devices): </span>
                    {GetObjectsCount(resultsData.verifiedTag)}
                </Text>
            :
                null
            }
            {GetObjectsCount(resultsData.unassociatedContext) ?
                <Text>
                    <span id="resultField">Number of unassociated Context: </span>
                    {GetObjectsCount(resultsData.unassociatedContext)}
                </Text>
            :
                null
            }
            {GetObjectsCount(resultsData.tagNotFoundInDb) ?
                <Text>
                    <span id="resultField">Number of TAGs(Devices) not found in DB: </span>
                    {GetObjectsCount(resultsData.tagNotFoundInDb)}
                </Text>
            :
                null
            }
            {GetObjectsCount(resultsData.duplicateTag) ?
                <Text>
                    <span id="resultField">Number of duplicate TAGs(Devices): </span>
                    {GetObjectsCount(resultsData.duplicateTag)}
                </Text>
            :
                null
            }
        </>
    )
}

function InvalidSection({ resultsData }) {
    return(
        <>
            <Divider orientation="horizontal"
                     size="small" />
            {GetObjectsCount(resultsData.tagWithInvalidCategory) ?
                <Text>
                    <span id="resultField">Number of TAGs(Devices) with invalid category: </span>
                    {GetObjectsCount(resultsData.tagWithInvalidCategory)}
                </Text>
            :
                null
            }
            {GetObjectsCount(resultsData.tagInInvalidFormat) ?
                <Text>
                    <span id="resultField">Number of TAGs(Devices) in invalid format: </span>
                    {GetObjectsCount(resultsData.tagInInvalidFormat)}
                </Text>
            :
                null
            }
            {GetObjectsCount(resultsData.multipleTagsInImage) ?
                <Text>
                    <span id="resultField">Number of Images containing multiple TAGs(Devices): </span>
                    {GetObjectsCount(resultsData.multipleTagsInImage)}
                </Text>
            :
                null
            }
            {GetObjectsCount(resultsData.tagNotFoundInImage) ?
                <Text>
                    <span id="resultField">Number of Images not containing TAG(Device): </span>
                    {GetObjectsCount(resultsData.tagNotFoundInImage)}
                </Text>
            :
                null
            }
            {GetObjectsCount(resultsData.failure) ?
                <Text>
                    <span id="resultField">Number of Images failed to be processed: </span>
                    {GetObjectsCount(resultsData.failure)}
                </Text>
            :
                null
            }
        </>
    )
}

export const ResultCard = ({ resultsData, setFiles, OpenInNewTab, setDisableBrowse, setDisableToggle,
                             setIsProcessing }) => {
    let processColor = "#00AA00";
    let processStatus = "DONE";
    if (resultsData.filesCount !== GetObjectsCount(resultsData.verifiedTag)) {
        processColor = "#AA0000";
        processStatus = "FAILED";
    }
    const styles = { successColor: { color: processColor } };
    return (
        <Card variation="default"
              id="resultsCard"
              width={{ base: "90%", large: "30%" }}>
            <Text id="resultsHeader">Results:</Text>
            <Divider orientation="horizontal"
                     size="small" />
            <Text>
                <span id="resultField">Status:</span>
                <span style={styles.successColor}> {processStatus}</span>
            </Text>
            <Text>
                <span id="resultField">Project completion: </span>
                {resultsData.completionPercentage}%
            </Text>
            <Text>
                <span id="resultField">Number of processed Images: </span>
                {resultsData.filesCount}
            </Text>
            {GetObjectsCount(resultsData.verifiedTag) ||
             GetObjectsCount(resultsData.unassociatedContext) ||
             GetObjectsCount(resultsData.tagNotFoundInDb) ||
             GetObjectsCount(resultsData.duplicateTag) ?
                <ValidSection resultsData={resultsData} />
            :
                null
            }
            {GetObjectsCount(resultsData.tagInInvalidFormat) ||
             GetObjectsCount(resultsData.tagWithInvalidCategory) ||
             GetObjectsCount(resultsData.multipleTagsInImage) ||
             GetObjectsCount(resultsData.tagNotFoundInImage) ||
             GetObjectsCount(resultsData.failure) ?
                <InvalidSection resultsData={resultsData} />
            :
                null
            }
            <Divider orientation="horizontal"
                     size="small" />
            <Text>View detailed report via this
                <Link id="reportLink"
                      onClick={() => OpenInNewTab(resultsData, setDisableBrowse)}
                      isExternal={true} > link
                </Link>.
            </Text>
            <Flex direction="row"
                  justifyContent="center"
                  alignItems="center" >
                <Button id="doneButton"
                        onClick={() => { DoneProcess(setFiles, setDisableBrowse,
                                                     setDisableToggle, setIsProcessing); }}
                        isDisabled={false}
                        variation="primary"
                        colorTheme="success"
                        gap="0.2rem" >
                    <BsCheckLg />Done
                </Button>
            </Flex>
        </Card>
    );
}
