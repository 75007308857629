import { Flex, Button, Card, Text } from '@aws-amplify/ui-react';

import { ChangeVisibilityById } from './utils';

import '@aws-amplify/ui-react/styles.css';
import './confirmCard.css';

function ButtonOnClick(setDisableBrowse, setDisableToggle) {
    ChangeVisibilityById("confirmCard", "none");
    setDisableBrowse(false);
    setDisableToggle(false)
}

export const ShowConfirm = (setDisableBrowse) => {
    setDisableBrowse(true);
    ChangeVisibilityById("confirmCard", "block");
}

export const ConfirmCard = ({ setDisableBrowse, setDisableToggle, setConfirmStatus }) => {
    return (
        <Card id="confirmCard"
              variation="default"
              width={{ base: "90%", large: "22%" }}>
            <Text id="confirmHeading">Context not detected</Text>
            <Text id="confirmText">{"Unable to detect context image because of one of the reasons:\n\n"}</Text>
            <Text id="confirmText">{"\t- The desired device is not found"}</Text>
            <Text id="confirmText">{"\t- Unable to detect TAG in image"}</Text>
            <Text id="confirmText">{"\nPlease manually confirm or re-take the image.\n\n"}</Text>
            <Flex direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center" >
                <Button id="confirmButton"
                        variation="primary"
                        onClick={() => {setConfirmStatus(1);
                                        ButtonOnClick(setDisableBrowse, setDisableToggle)}}>Confirm</Button>
                <Button id="retakeButton"
                        variation="primary"
                        onClick={() => {setConfirmStatus(2);
                                        ButtonOnClick(setDisableBrowse, setDisableToggle)}}>Retake</Button>
            </Flex>
        </Card>
    );
}
