import { Loader, Text, Flex } from '@aws-amplify/ui-react';
import './loader.css';

import { Sleep } from '../../common/utils';

export async function UpdateState(text, time, stepInfo, setPercentage, setText) {
    const difference = stepInfo["current"] - stepInfo["previous"];
    const step = difference > 10 ? 100 : 1;
    const percentageStep =  difference / step;
    const sleepTime = time / step;
    while (stepInfo["previous"] < stepInfo["current"]) {
        stepInfo["previous"] += percentageStep;
        setPercentage(stepInfo["previous"]);
        setText(text+" ("+Math.ceil(stepInfo["previous"])+"%)");
        await Sleep(sleepTime);
    }
}

export const ProgressBar = ({ percentage, text }) => {
    return (
        <div>
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="1.5rem" >
                <Loader id="progressbar"
                        variation="linear"
                        percentage={percentage}
                        isDeterminate={true}
                        isPercentageTextHidden={true}
                        size="large" />
                <Text id="currentStatus"
                      variation="primary">Current status: {text}</Text>
            </Flex>
        </div>
    );
};
