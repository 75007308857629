import { Text, Divider, Flex } from '@aws-amplify/ui-react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { BsPersonBadge, BsEscape, BsTextareaT} from "react-icons/bs";
import './sideBar.css';

export const SideBar = ({ toggleSideBar, setToggleSideBar, qrMode, setQrMode, user, signOut }) => {
    const SwitchQrMode = () => {
        setQrMode(! qrMode);
        setToggleSideBar(false);
    };
    return (
        <Sidebar id="sideBar"
                 width="300px"
                 onBackdropClick={() => setToggleSideBar(false)}
                 toggled={toggleSideBar}
                 breakPoint="all" >
            <Menu>
                <MenuItem disabled={true} >
                    <Flex direction="row"
                          alignItems="center"
                          justifyContent="center" >
                        <Text>
                            <BsPersonBadge /> Welcome {user.username}
                        </Text>
                    </Flex>
                </MenuItem>
                {/*
                <Divider />
                <MenuItem id="menuItem"
                          onClick={SwitchQrMode}>
                    <Text>
                        <BsTextareaT /> Switch to {qrMode ? "TAG" : "QR"} based verification
                    </Text>
                </MenuItem>
                */}
                <MenuItem id="signOutItem"
                          onClick={signOut} >
                    <Flex direction="row"
                          alignItems="center"
                          justifyContent="center"
                          gap="0.1rem">
                        <BsEscape />Sign out
                    </Flex>
                </MenuItem>
            </Menu>
        </Sidebar>
    )
}
