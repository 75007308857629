import { Flex, Text, SwitchField, Button } from '@aws-amplify/ui-react';
import { BsBrightnessHighFill, BsMoonFill, BsList } from "react-icons/bs";

import './navigationBar.css';
import packageJson from '../../../package.json';

function ChangeTheme(isChecked) {
    if (isChecked) {
        document.body.classList.add('dark-mode')
    } else {
        document.body.classList.remove('dark-mode')
    }
}

export const NavBar = ({ title, setToggleSideBar, disableToggle }) => {
    return (
        <Flex id="navBar"
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              wrap="nowrap">
            <Button id="sideBarButton"
                    disabled={disableToggle}
                    onClick={() => {setToggleSideBar(true)}}>
                <BsList />
            </Button>
            <Flex direction="column"
                  alignItems="center"
                  alignContent="center"
                  gap="0.1rem">
                <Text fontSize="large">{title}</Text>
                <Text fontSize="small">Version: {packageJson.version}</Text>
            </Flex>
            <Flex direction="row"
                  alignItems="center"
                  alignContent="center"
                  gap="0.2rem">
                <BsBrightnessHighFill />
                <SwitchField isDisabled={false}
                             isLabelHidden={true}
                             onChange={(event) => ChangeTheme(event.target.checked)}></SwitchField>
                <BsMoonFill />
            </Flex>
        </Flex>
    )
}
