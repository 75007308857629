import { BsCloudUploadFill, BsClockFill, BsCloudDownloadFill, BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

export const FileStatus = ({ state }) => {
    switch (state) {
        case "upload":
            return <span style={{color: 'orange'}}> <BsCloudUploadFill /></span>;
        case "process":
            return <span style={{color: 'blue'}}> <BsCloudDownloadFill /></span>;
        case "success":
            return <span style={{color: 'green'}}> <BsCheckCircleFill /></span>;
        case "error":
            return <span style={{color: 'red'}}> <BsFillXCircleFill /></span>;
        default:
            return <span style={{color: 'grey'}}> <BsClockFill /></span>;
    }
}
