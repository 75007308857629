import { useRef } from 'react';
import { Button, VisuallyHidden } from '@aws-amplify/ui-react';
import { BsFileEarmarkImage } from "react-icons/bs";

import { IsMobile } from './utils';
import { ShowError } from './errorCard';
import { UUIDV4 } from './utils';

import imageCompression from 'browser-image-compression';

import '@aws-amplify/ui-react/styles.css';
import './fileSelector.css';

function ModifyFile(files) {
    let file = files[0];
    let blob = file.slice(0, file.size, file.type);
    return new File([blob], UUIDV4()+"."+file.name.split('.').pop(), {type: file.type});
};

async function CompressImage(file, setCompressionPercentage) {
    const options = {
        maxSizeMB: 5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        alwaysKeepResolution: true,
        onProgress: setCompressionPercentage,
    }
    try {
        return await imageCompression(file, options);
    } catch (error) {
        console.log(error);
    }
    return null;
}

async function CheckFiles(files, acceptedFileTypes, setCompressionPercentage) {
    const responses = Array.from(files).map(async (file, ) => {
        let heading;
        let message;
        if (! acceptedFileTypes.includes(file.type)) {
            heading = "Incorrect file type";
            message = IsMobile ? "Please configure Camera to save image in JPG format."
                               : "Please select '" + acceptedFileTypes.join(" | ").replaceAll("image/", "") + "' file only!\n"
        } else if (file.size >= 5000000) {
            file = await CompressImage(file, setCompressionPercentage);
            if (file.size >= 5000000) {
                heading = "File too large("+(file.size/1024/1024).toFixed(2)+"MB)";
                if (IsMobile) {
                    message = "Please lower your Camera resolution and try again.";
                } else {
                    message = "Please select image file with size <5MB!\n";
                }
            }
        }
        return {
            file: file,
            heading: heading,
            message: message
        };
    });
    return await Promise.all(responses);
}

async function CheckValidityOfFile(files, acceptedFileTypes, setDisableBrowse, setCompressionPercentage) {
    let results = await CheckFiles(files, acceptedFileTypes, setCompressionPercentage);
    var heading;
    var message;
    var suggestions = [];
    var allowedFiles = [];
    for (let i in results) {
        let response = results[i];
        if (response.heading && response.message) {
            if (! heading) {
                heading = "Errors during image(s) pre-processing";
            }
            if (! message) {
                message = "During image(s) pre-processing the following incompatabilities found:\n\n";
            }
            message += "\t" + response.file.name + ": " + response.heading + "\n";
            if (! suggestions.includes(response.message)){
                suggestions.push(response.message);
            }
        } else {
            allowedFiles.push(response.file);
        }
    }
    if (heading && message) {
        for (let i in suggestions) {
            message += `\n${parseInt(i)+1}) ` + suggestions[i] + "\n";
        }
        ShowError(message);
    } else {
        setDisableBrowse(false);
    }
    return allowedFiles;
}

async function onFilePickerChange(event, acceptedFileTypes, isMultiple, setFile, setDisableBrowse,
                                  setCompressionPercentage) {
    setDisableBrowse(true);
    setFile(isMultiple ? [] : null);
    const {files} = event.target;
    if (!files || !files.length) {
        return;
    }
    let allowedFiles = await CheckValidityOfFile(files, acceptedFileTypes, setDisableBrowse,
                                                 setCompressionPercentage);
    let newFiles;
    if (allowedFiles.length) {
        if (isMultiple) {
            newFiles = allowedFiles;
        } else if (IsMobile) {
            newFiles = ModifyFile(allowedFiles);
        } else {
            newFiles = allowedFiles[0];
        }
    }
    if (Array.isArray(newFiles)) {
        newFiles.map((file) => { file["state"] = "none"; return file });
    }
    setFile(newFiles);
    event.target.value = "";
}

export const FileSelector = ({ setFile, disableBrowse, setDisableBrowse, setCompressionPercentage,
                               acceptedFileTypes, isMultiple, processPhase, setStartCapture }) => {
    const hiddenInput = useRef(null);
    const buttonText = IsMobile ? "Capture" : "Browse";
    return (
        <div id="fileSelector">
            <Button id="browseButton"
                    size="small"
                    isDisabled={disableBrowse}
                    variation="primary"
                    onClick={() => {"additional" === processPhase ? setStartCapture(true) : hiddenInput.current.click() }}
                    gap="0.2rem">
                <BsFileEarmarkImage /> {buttonText}
            </Button>
            <VisuallyHidden>
                <input type="file"
                       tabIndex={-1}
                       multiple={isMultiple}
                       ref={hiddenInput}
                       onChange={(event) => onFilePickerChange(event,
                                                               acceptedFileTypes,
                                                               isMultiple,
                                                               setFile,
                                                               setDisableBrowse,
                                                               setCompressionPercentage) }
                       capture="environment"
                       accept={acceptedFileTypes.join(',')} />
            </VisuallyHidden>
        </div>
    );
}
