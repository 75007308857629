import { Grid, Flex, Card, Button, Text, Divider } from '@aws-amplify/ui-react';
import { BsCheckLg } from "react-icons/bs";

import { ChangeVisibilityById } from '../../common/utils';
import { IsOutOfRange } from '../../common/heightSlider';

import './resultsCard.css';

function ResetProcess(setFile, setDisableBrowse, setDisableToggle, setProcessPhase, setDeviceStatus,
                      setContextStatus, setAdditionalStatus, setIsProcessing) {
    setFile(null);
    setProcessPhase("device");
    ChangeVisibilityById("cancelButton", "none");
    ChangeVisibilityById("resultsCard", "none");
    setDeviceStatus("default");
    setContextStatus("default");
    setAdditionalStatus("default");
    setDisableBrowse(false);
    setDisableToggle(false);
    setIsProcessing(false);
}

export const ResultCard = ({ resultsData, doneStatus, setFile, setDisableBrowse, setDisableToggle,
                             setProcessPhase, setDeviceStatus, setContextStatus, setAdditionalStatus,
                             setIsProcessing }) => {
    let processColor = "#00AA00";
    let processStatus = "DONE";
    if ("error" === doneStatus) {
        processColor = "#AA0000";
        processStatus = "FAILED";
    }
    let deviceStatusColor = "VERIFIED TAG" === resultsData.deviceStatus ? "#00AA00" : "#AA0000";
    const styles = { successColor: { color: processColor },
                     deviceColor: { color: deviceStatusColor } };
    return (
        <Card variation="default"
              id="resultsCard"
              width={{ base: "90%", large: "25%" }}>
            <Text id="resultsHeader"
                  as="p">Results:</Text>
            <Divider orientation="horizontal"
                     size="small" />
            <Grid id="deviceInfo"
                  alignItems="center"
                  templateColumns="1fr 1fr"
                  gap="0rem" >
                <Text>
                    <span id="resultField">Status:</span>
                    <span id="processStatus"
                          style={styles.successColor}> {processStatus}</span>
                </Text>
                <Text>
                    <span id="resultField">Project completion:</span>
                    <span id="completionStatus"> {resultsData.completionPercentage}%</span>
                </Text>
            </Grid>
            <Divider orientation="horizontal"
                     size="small" />
            <Text>
                <span id="resultField">Device ID:</span> {resultsData.deviceId}
            </Text>
            <Text>
                <span id="resultField">Device ID Status:</span>
                <span style={styles.deviceColor}> {resultsData.deviceStatus}</span>
            </Text>
            <Text>
                <span id="resultField">Architectural Drawing:</span> {resultsData.architecturalDrawing}
            </Text>
            <Text id="otherTags">
                <span id="resultField">Other TAGs:</span> {resultsData.otherTags}
            </Text>
            <Divider orientation="horizontal"
                     size="small" />
            <Text>
                <span>
                    <span id="resultField">Context Attached:</span> {resultsData.isContext}
                    { "isContextForced" in resultsData ?
                        <span style={{color: "#AA0000"}}> (manually confirmed)</span>
                      :
                        null
                    }
                </span>
            </Text>
            <Text>
                <span>
                    <span id="resultField">Placement Height:</span> {resultsData.placementHeight}
                    { IsOutOfRange ?
                        <span style={{color: "#AA0000"}}> (manually confirmed)</span>
                      :
                        null
                    }
                </span>
            </Text>
            <Flex direction="row"
                  justifyContent="center"
                  alignItems="center" >
                <Button id="doneButton"
                        onClick={() => { ResetProcess(setFile, setDisableBrowse,
                                                      setDisableToggle, setProcessPhase,
                                                      setDeviceStatus, setContextStatus,
                                                      setAdditionalStatus, setIsProcessing); }}
                        isDisabled={false}
                        variation="primary"
                        colorTheme="success"
                        gap="0.2rem" >
                    <BsCheckLg />Done
                </Button>
            </Flex>
        </Card>
    );
}
