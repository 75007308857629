import { Text, Flex, Badge } from '@aws-amplify/ui-react';
import { BsTextareaT, BsQrCodeScan, BsSearch, BsRulers, BsEmojiSmileFill, BsEmojiFrownFill,
         BsEmojiNeutralFill, BsCaretRightFill } from "react-icons/bs";
import { useEffect } from 'react';

import { Sleep } from './utils';

import './statusline.css';

export async function UpdateState(name, percentage) {
    const badge = document.getElementById(name);
    const time = 100;
    const step = 1;
    var current = badge.style.getPropertyValue("--percentage");
    current = current ? parseInt(current) : 0;
    const difference = percentage - current;
    const sleepTime = time / difference;
    while (current < percentage) {
        current += step;
        badge.style.setProperty("--percentage", current);
        await Sleep(sleepTime);
    }
    if (100 === current) {
        badge.style.setProperty("--percentage", 0);
    }
}

function Phase({ name, status, Icon }) {
    const titlizedText = name.charAt(0).toUpperCase() + name.slice(1);
    return (
        <>
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="0rem" >
                <Badge id={name + "Badge"}
                       variation={status}>
                    <Text><Icon /></Text>
                </Badge>
                <Text>{titlizedText}</Text>
            </Flex>
            <Text id="arrow"><BsCaretRightFill /></Text>
        </>
    );
};

export const StatusLine = ({ mode, deviceStatus, contextStatus, additionalStatus,
                             doneStatus, setDoneStatus }) => {
    useEffect(() => {
        let statuses = [deviceStatus, contextStatus, additionalStatus];
        if (statuses.filter(x => ["success", "info"].includes(x)).length === 3) {
            setDoneStatus("success");
        } else if (statuses.filter(x => "error" === x).length) {
            setDoneStatus("error");
        } else {
            setDoneStatus("default");
        }
    }, [deviceStatus, contextStatus, additionalStatus]);
    return (
        <Flex id="processStatus"
              direction="row"
              justifyContent="center"
              alignItems="space-between"
              alignContent="center"
              wrap="nowrap"
              gap="1.0rem">
            <Phase name="device"
                   status={deviceStatus}
                   Icon={"tag" === mode ? BsTextareaT : BsQrCodeScan} />
            <Phase name="context"
                   status={contextStatus}
                   Icon={BsSearch} />
            <Phase name="additional"
                   status={additionalStatus}
                   Icon={BsRulers} />
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="0rem" >
                <Badge id="doneBadge"
                    variation={doneStatus}>
                    <Text>{ "success" === doneStatus ? <BsEmojiSmileFill />
                                                     : "default" === doneStatus ? <BsEmojiNeutralFill />
                                                                                : <BsEmojiFrownFill />}</Text>
                </Badge>
                <Text>Final</Text>
            </Flex>
        </Flex>
    );
};
