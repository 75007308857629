import { useState, useEffect } from 'react';
import { Flex, useBreakpointValue } from '@aws-amplify/ui-react';

import { SideBar } from './common/sideBar';
import { NavBar } from './common/navigationBar';
import { SetMobile } from './common/utils';
import { QrApp } from './qr/main';
import { TagApp } from './tag/main';

import '@aws-amplify/ui-react/styles.css';
import './main.css';

export const Main = ({ signOut, user }) => {
    const title = "Installation Verifier";
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [qrMode, setQrMode] = useState(false);
    const [disableBrowse, setDisableBrowse] = useState(false);
    const [disableToggle, setDisableToggle] = useState(false);
    SetMobile(useBreakpointValue({
        base: true,
        large: false
    }))
    useEffect(() => {
        document.title = title
    }, []);
    return (
        <>
            <SideBar toggleSideBar={toggleSideBar}
                     setToggleSideBar={setToggleSideBar}
                     qrMode={qrMode}
                     setQrMode={setQrMode}
                     signOut={signOut}
                     user={user} />
            <main>
                <Flex direction="column"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1.5rem">
                    <NavBar title={title}
                            setToggleSideBar={setToggleSideBar}
                            disableToggle={disableToggle} />
                    { qrMode ?
                        <QrApp disableBrowse={disableBrowse}
                               setDisableBrowse={setDisableBrowse}
                               setDisableToggle={setDisableToggle} />
                    :
                        <TagApp disableBrowse={disableBrowse}
                                setDisableBrowse={setDisableBrowse}
                                setDisableToggle={setDisableToggle} />
                    }
                </Flex>
            </main>
        </>
    );
}
