import { Grid, Autocomplete, Text } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import './selector.css';

function CheckSelector(value, data, setValue, setStatus, regExp) {
    setValue(value);
    if (value.length === 0) {
        setStatus("Empty");
        return;
    }
    if (! RegExp(regExp).test(value)) {
        setStatus("Invalid");
        return;
    }
    let is_found = false;
    for (let i in data) {
        let tmp = data[i];
        if (tmp.id === value) {
            is_found = true;
            break;
        }
    }
    setStatus(is_found ? "Exists" : "Invalid");
}

function CustomFilter(option, value) {
    const valueUpper = value.toUpperCase();
    const optionUpper = option.label.toUpperCase();
    return optionUpper.startsWith(valueUpper);
}

function Selector({ is_facility, id, value, options, isDisabled, isLoading, placeholder,
                    status_label, current_status, setValue, setStatus }) {
    let regExp = is_facility ? "^[A-Z]{3,4}[0-9]{0,4}$" : "^[T][0-9]{8}$"
    return (
        <>
            <Autocomplete id={id}
                          options={options}
                          placeholder={placeholder}
                          isLoading={isLoading}
                          label=""
                          size="small"
                          hasSearchIcon={false}
                          value={value}
                          isDisabled={isDisabled}
                          onChange={(event) => { CheckSelector(event.target.value, options,
                                                               setValue, setStatus,
                                                               regExp); }}
                          onSelect={(event) => { CheckSelector(event.id, options,
                                                               setValue, setStatus,
                                                               regExp); }}
                          onClear={() => { setValue("");
                                           setStatus("Empty"); }}
                          optionFilter={(option, value) => { return CustomFilter(option, value); }}
            />
            <Text>{status_label}:</Text>
            <Text color={current_status === "Exists" ? "green" : "red"}>{current_status}</Text>
        </>
    );
}

export const SelectorField = ({ facilityCode, facilityCodes, isFacilityLoading, setFacilityCode,
                                facilityCodeStatus, setFacilityCodeStatus, projectId, projectIds,
                                setProjectId, projectIdStatus, setProjectIdStatus, isProcessing }) => {
    const [isProjectLoading, setIsProjectLoading] = useState(true);
    const [isProjectDisabled, setIsProjectDisabled] = useState(true);
    useEffect(() => {
        let flag = ["Empty", "Not set", "Invalid"].includes(facilityCodeStatus);
        setIsProjectLoading(flag);
        setIsProjectDisabled(flag);
        if (flag) {
            setProjectId("");
            setProjectIdStatus("Empty");
        }
    }, [facilityCodeStatus, setProjectIdStatus]);
    return (
        <Grid id="selector"
              alignItems="center"
              templateColumns="3fr 0.2fr 1fr"
              templateRows="3rem 3rem"
              gap="0.5rem" >
            <Selector is_facility={true}
                      id="facilityCodeSelector"
                      value={facilityCode}
                      options={facilityCodes}
                      isLoading={isFacilityLoading}
                      isDisabled={isProcessing}
                      placeholder="Enter/select Facility Code"
                      status_label="FC"
                      current_status={facilityCodeStatus}
                      setValue={setFacilityCode}
                      setStatus={setFacilityCodeStatus} />
            <Selector is_facility={false}
                      id="projectIdSelector"
                      value={projectId}
                      options={projectIds}
                      isDisabled={isProjectDisabled || isProcessing}
                      isLoading={isProjectLoading}
                      placeholder="Enter/select Project ID"
                      status_label="PID"
                      current_status={projectIdStatus}
                      setValue={setProjectId}
                      setStatus={setProjectIdStatus} />
        </Grid>
    );
}
