import { Grid, Flex, Card, Button, Text, Divider } from '@aws-amplify/ui-react';
import { BsCheckLg } from "react-icons/bs";

import { ChangeVisibilityById } from '../common/utils';
import { IsOutOfRange } from '../common/heightSlider';

import './resultsCard.css';

function ResetProcess(setFile, setDisableBrowse, setDisableToggle, setProcessPhase, setDeviceStatus,
                      setContextStatus, setAdditionalStatus) {
    setFile(null);
    setProcessPhase("device");
    ChangeVisibilityById("cancelButton", "none");
    ChangeVisibilityById("resultsCard", "none");
    setDeviceStatus("default");
    setContextStatus("default");
    setAdditionalStatus("default");
    setDisableBrowse(false);
    setDisableToggle(false);
}

export const ResultCard = ({ resultsData, setFile, setDisableBrowse, setDisableToggle, setProcessPhase, setDeviceStatus,
                             setContextStatus, setAdditionalStatus }) => {
    let processColor = "#AA0000";
    let processStatus = "FAILURE";
    let isFailure = resultsData.isFailure;
    if (! isFailure) {
        processStatus = "DONE";
        processColor = "#00AA00";
    }
    const styles = { successColor: { color: processColor } }
    return (
        <Card variation="primary"
              id="resultsCard"
              width={{ base: "90%", large: "30%" }}>
            <Text id="resultsHeader"
                  as="p">Result:</Text>
            <Divider orientation="horizontal"
                     size="small" />
            <Text>
                <span id="resultField">Status:</span>
                <span style={styles.successColor}> {processStatus}</span>
            </Text>
            {
                isFailure ?
                <Text>
                    <span id="resultField">Reason:</span> {resultsData.faillureReason}
                </Text>
            :
                null
            }
            <Divider orientation="horizontal"
                     size="small" />
            <Grid id="deviceInfo"
                  alignItems="center"
                  templateColumns="1fr 1fr"
                  gap="0rem" >
                <Text>
                    <span id="resultField">Facility Code:</span> {resultsData.facilityCode}
                </Text>
                <Text>
                    <span id="resultField">Project ID:</span> {resultsData.projectId}
                </Text>
                <Text>
                    <span id="resultField">Space ID:</span> {resultsData.spaceId}
                </Text>
                <Text>
                    <span id="resultField">Device ID:</span> {resultsData.deviceId}
                </Text>
            </Grid>
            <Divider orientation="horizontal"
                     size="small" />
            <Text>
                <span id="resultField">SID-DID Association:</span> {resultsData.correspondenceStatus}
            </Text>
            <Text id="placementField">
            <span>
                <span id="resultField">Placement Height:</span> {resultsData.placementHeight}
                { IsOutOfRange ?
                    <span style={{color: "#AA0000"}}> (manually confirmed)</span>
                  :
                    null
                }
            </span>
            </Text>
            <Text id="fovField">
                <span id="resultField">FOV:</span> {resultsData.fovStatus}
            </Text>
            <Divider orientation="horizontal"
                     size="small" />
            <Text>
                <span id="resultField">Project completion:</span> {resultsData.completionPercentage}%
            </Text>
            <Flex direction="row"
                  justifyContent="center"
                  alignItems="center" >
                <Button id="doneButton"
                        onClick={() => { ResetProcess(setFile, setDisableBrowse,
                                                      setDisableToggle, setProcessPhase,
                                                      setDeviceStatus, setContextStatus,
                                                      setAdditionalStatus); }}
                        isDisabled={false}
                        variation="primary"
                        colorTheme="success"
                        gap="0.2rem" >
                    <BsCheckLg />Done
                </Button>
            </Flex>
        </Card>
    );
}
