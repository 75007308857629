import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button, Flex, Text, SliderField } from "@aws-amplify/ui-react";

import { UUIDV4, ChangeVisibilityById } from "../../common/utils";
import { ShowError } from "../../common/errorCard";

import "./heightMeasurement.css";

export let IsOutOfRange = 0;

function SetError(message, setStartCapture) {
    if (0 !== message.length) {
        ShowError(message);
    };
    setStartCapture(false);
}

async function StartOrientationListener(handleOrientation, setStartCapture) {
    if ("function" === typeof DeviceOrientationEvent.requestPermission) {
        try {
            const permissionState = await DeviceOrientationEvent.requestPermission();
            if ("granted" === permissionState) {
                window.addEventListener("deviceorientation", handleOrientation);
                return true;
            } else if ("denied" === permissionState){
                SetError("Failed to access Gyroscope. </br>" +
                "Please wipe this website data in order to re-grant the permission: </br>" +
                "<ol><li>Open '<strong>Settings</strong>'. </li>" +
                "<li>Navigate to '<strong>Safari</strong>' section. </li>"  +
                "<li>Scroll to the bottom and open '<strong>Advanced</strong>' section. </li>" +
                "<li>Open '<strong>Website Data</strong>' section. </li>" +
                `<li>Enter <strong>${window.location.hostname}</strong> in <strong>Search</strong> field. </li>` +
                "<li>Swipe left the results. </li>" +
                "<li>Go back to this page and refresh. </li></ol>"
, setStartCapture);
                return false;
            }
        } catch(error) {
            SetError("Failed to access Gyroscope", setStartCapture);
            return false;
        };
    } else {
        if (window.DeviceOrientationEvent) {
            window.addEventListener("deviceorientation", handleOrientation);
            return true;
        } else {
            SetError("Failed to access Gyroscope", setStartCapture);
            return false;
        }
    }
};

function StopOrientationListener(handleOrientation) {
    window.removeEventListener("deviceorientation", handleOrientation);
};

async function StartCamera(handleOrientation, videoRef, setIsStreaming, setIsCaptured, setCaptureDisabled,
                           isStarted, setStartCapture) {
    const orientationGranted = await StartOrientationListener(handleOrientation, setStartCapture);
    if (orientationGranted) {
        try {
            const getMediaData = async () => {
                try {
                    videoRef.current.srcObject = await navigator.mediaDevices.getUserMedia(constraints);
                } catch (error) {
                    SetError("Falied to access Camera", setStartCapture);
                }
            };
            const constraints = {
                video: { facingMode: "environment" }
            };
            if (videoRef.current) {
                getMediaData();
            }
            setIsStreaming(true);
            setIsCaptured(false);
            setCaptureDisabled(true);
            isStarted.current = true;
        } catch (error) {
            SetError("Falied to access Camera", setStartCapture);
            setIsStreaming(false);
            setIsCaptured(false);
            setCaptureDisabled(true);
        }
    }
};

function StopCamera(handleOrientation, videoRef, setIsCaptured, setCaptureDisabled,
                    animationFrameRef, setIsStreaming, setStartCapture, isStarted) {
    StopOrientationListener(handleOrientation);
    if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => {track.stop()});
    }
    setIsCaptured(false);
    setCaptureDisabled(true);
    if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
    }
    setIsStreaming(false);
    isStarted.current = false;
    setStartCapture(false);
};

function GetPointerPosition(event, canvasRef) {
    const rect = canvasRef.current.getBoundingClientRect();
    var x = (event.clientX - rect.left) * (canvasRef.current.width / rect.width);
    var y = (event.clientY - rect.top) * (canvasRef.current.height / rect.height);
    if (x < 0) {
        x = 0;
    } else if (x > canvasRef.current.width) {
        x = canvasRef.current.width;
    }
    if (y < 0) {
        y = 0;
    } else if (y > canvasRef.current.height) {
        y = canvasRef.current.height;
    }
    return {
        x: x,
        y: y,
    };
};

function IsPointInTheCircle(x1, x2, y1, y2, radius) {
    const dist = Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
    return dist <= 3 * radius;
}

function HandlePointerDown(event, canvasRef, circleOne, circleTwo, setIsDrawing, setCircleIndex,
                           radius) {
    const { x, y } = GetPointerPosition(event, canvasRef);
    if (IsPointInTheCircle(circleOne.x, x, circleOne.y, y, radius)) {
        setIsDrawing(true);
        setCircleIndex(0);
    } else if (IsPointInTheCircle(circleTwo.x, x, circleTwo.y, y, radius)) {
        setIsDrawing(true);
        setCircleIndex(1);
    }
};

function DrawCircle(context, x, y, radius, color, fill) {
    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI);
    if (fill) {
        context.fillStyle = color;
        context.fill();
    } else {
        context.strokeStyle = "white";
        context.stroke();
    }
};

function HandlePointerUp(setIsDrawing, setCircleIndex) {
    setIsDrawing(false);
    setCircleIndex(null);
};

function HandlePointerMove(event, isDrawing, canvasRef, circleIndex, circleOne, setCircleOne, setCircleTwo) {
    if (isDrawing) {
        let { x, y } = GetPointerPosition(event, canvasRef);
        x = circleOne.x;
        if (circleIndex !== null) {
            if (circleIndex === 0) {
                setCircleOne({ x, y });
            } else {
                setCircleTwo({ x, y });
            }
        }
    }
};

function CaptureOnClick(readyState, isCaptured, setIsCaptured, videoRef, canvasRef,
                        handleOrientation, setCaptureDisabled, animationFrameRef, setIsStreaming,
                        setStartCapture, setFile, isStarted) {
    if (readyState >= 2 && !isCaptured) {
        setIsCaptured(true);
        videoRef.current.pause();
        StopOrientationListener(handleOrientation);
    } else if (isCaptured) {
        let canvas = canvasRef.current;
        canvas.toBlob((blob) => {
            setFile(new File([blob], UUIDV4()+".png", {type: "image/png"}));
        }, "image/png", 1);
        if (isStarted.current) {
            StopCamera(handleOrientation, videoRef, setIsCaptured, setCaptureDisabled,
                       animationFrameRef, setIsStreaming, setStartCapture, isStarted);
        }
    }
};

function RetakeOnClick(videoRef, setIsCaptured, setCaptureDisabled, handleOrientation, setStartCapture) {
    if (videoRef.current.paused === true) {
        videoRef.current.play();
        setIsCaptured(false);
        setCaptureDisabled(false);
        StartOrientationListener(handleOrientation, setStartCapture);
    }
};

export const HeightMeasurement = ({ height, setHeight, resultsData, startCapture, setStartCapture, setFile }) => {
    const radius = 15;
    const isDeviceCamera = resultsData.deviceId.startsWith("CAM");
    const goldenMinimumFeet = isDeviceCamera ? 9.9 : 5;
    const goldenMinimumPx = isDeviceCamera ? 137 : 238;
    const threshold = isDeviceCamera ? 120.0 : 42.0;
    const minDistance = goldenMinimumFeet;
    const maxDistance = goldenMinimumFeet * 2;
    const [distance, setDistance] = useState(goldenMinimumFeet);
    const [isStreaming, setIsStreaming] = useState(false);
    const [circleOne, setCircleOne] = useState({ x: 0, y: 0 });
    const [circleTwo, setCircleTwo] = useState({ x: 0, y: 0 });
    const [circleIndex, setCircleIndex] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [readyState, setReadyState] = useState(0);
    const [orientation, setOrientation] = useState({ a: 0, b: 0, y: 0 });
    const [isCaptured, setIsCaptured] = useState(false);
    const [captureDisabled, setCaptureDisabled] = useState(true);
    const [distMultiplier, setDistMultiplier] = useState(goldenMinimumPx / 34);
    const isStarted = useRef(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const animationFrameRef = useRef(null);
    const handleOrientation = useCallback((event) => {
        setOrientation({ a: event.alpha, b: event.beta, y: event.gamma });
    }, []);
    const drawVideoOnCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const video = videoRef.current;
        setReadyState(video.readyState);
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        if (video && canvas && isStreaming) {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            context.setLineDash([5, 5]);
            context.lineWidth = 3;
            DrawCircle(context, circleOne.x, circleOne.y, 4, "white", true);
            DrawCircle(context, circleOne.x, circleOne.y, radius, "white", false);
            DrawCircle(context, circleTwo.x, circleTwo.y, 4, "white", true);
            DrawCircle(context, circleTwo.x, circleTwo.y, radius, "white", false);
            context.beginPath();
            context.moveTo(circleOne.x, circleOne.y);
            context.lineTo(circleTwo.x, circleTwo.y);
            context.strokeStyle = "white";
            context.stroke();
            context.font = "bold 30px Arial";
            const currentHeight = Math.sqrt(
                (circleOne.x - circleTwo.x) ** 2 + (circleOne.y - circleTwo.y) ** 2,
            ) / distMultiplier;
            const x = (circleOne.x + circleTwo.x) / 2;
            const y = (circleOne.y + circleTwo.y) / 2;
            const text = currentHeight.toFixed(1) + "″"
            context.setLineDash([]);
            context.strokeStyle = "black";
            context.strokeText(text, x, y);
            context.fillStyle = "white";
            context.fillText(text, x, y);
            setHeight(currentHeight.toFixed(1));
        }
        animationFrameRef.current = requestAnimationFrame(drawVideoOnCanvas);
    };
    ChangeVisibilityById("errorCard", "none");
    useEffect(() => {
        resultsData["placementHeight"] = height + '"';
        let heightFloat = parseFloat(height);
        IsOutOfRange = (isDeviceCamera ? (heightFloat < threshold) : (heightFloat !== threshold)) | 0;
    }, [height]);
    useEffect(() => {
        var captureButton = document.getElementById("captureHeightButton");
        if (IsOutOfRange && isCaptured) {
            captureButton.classList.add("error");
        } else {
            captureButton.classList.remove("error");
        }
    }, [isCaptured, IsOutOfRange])
    useEffect(() => {
        if (startCapture && !isStarted.current) {
            StartCamera(handleOrientation, videoRef, setIsStreaming, setIsCaptured,
                        setCaptureDisabled, isStarted, setStartCapture);
        }
    }, [startCapture]);
    useEffect(() => {
        const horizontalValue = orientation.y;
        const verticalValue = orientation.b;
        const tiltInfo = {
            left: horizontalValue < -20,
            right: horizontalValue > 20,
            top: verticalValue < 75,
            bottom: verticalValue > 105
        };
        const canvas = document.getElementById("measurementCanvas");
        for (let i of ["left", "top", "right", "bottom"]) {
            if (tiltInfo[i]) {
                canvas.classList.add(i + "-bad");
                canvas.classList.remove(i + "-good");
            } else {
                canvas.classList.remove(i + "-bad");
                canvas.classList.add(i + "-good");
            }
        }
        if (!isCaptured && isStreaming && !tiltInfo.left && !tiltInfo.right && !tiltInfo.top
            && !tiltInfo.bottom) {
            setCaptureDisabled(false);
        } else if (isCaptured) {
            for (let i of ["left", "top", "right", "bottom"]) {
                canvas.classList.remove(i + "-bad");
                canvas.classList.remove(i + "-good");
            }
            setCaptureDisabled(false);
        } else {
            setCaptureDisabled(true);
        }
    }, [isCaptured, isStreaming, orientation]);
    useEffect(() => {
        const initializeCircles = () => {
            const canvas = canvasRef.current;
            setCircleOne({
                x: canvas.width / 2,
                y: (canvas.height - threshold * distMultiplier) / 2,
            });
            setCircleTwo({
                x: canvas.width / 2,
                y: (canvas.height - threshold * distMultiplier) / 2 + threshold * distMultiplier,
            });
        };
        if (isStreaming && readyState >= 2) {
            const canvas = canvasRef.current;
            const video = videoRef.current;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            initializeCircles();
        }
    }, [isStreaming, readyState, distance]);
    useEffect(() => {
        if (isStreaming) {
            drawVideoOnCanvas();
        }
        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [readyState, isStreaming, circleOne, circleTwo, distance]);
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden && isStarted.current) {
                StopCamera(handleOrientation, videoRef, setIsCaptured, setCaptureDisabled,
                           animationFrameRef, setIsStreaming, setStartCapture, isStarted);
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);
    return (
        <Flex id="canvasDiv"
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="0.5rem" >
            <div align="center" >
                <video id="cameraStream"
                       ref={videoRef}
                       autoPlay
                       playsInline />
                <canvas id="measurementCanvas"
                        ref={canvasRef}
                        onPointerDown={(event) => {HandlePointerDown(event, canvasRef,
                                                                     circleOne, circleTwo,
                                                                     setIsDrawing, setCircleIndex,
                                                                     radius);}}
                        onPointerMove={(event) => {HandlePointerMove(event, isDrawing, canvasRef,
                                                                     circleIndex, circleOne,
                                                                     setCircleOne, setCircleTwo);}}
                        onPointerUp={() => {HandlePointerUp(setIsDrawing, setCircleIndex);}}
                        onPointerCancel={() => {HandlePointerUp(setIsDrawing, setCircleIndex);}}
                        onPointerOut={() => {HandlePointerUp(setIsDrawing, setCircleIndex);}}
                        onPointerLeave={() => {HandlePointerUp(setIsDrawing, setCircleIndex);}} />
            </div>
            <Flex id="canvasControllers"
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center" >
                <Button id="captureHeightButton"
                        variation="primary"
                        colorTheme={IsOutOfRange ? "error" : "success"}
                        onClick={() => {CaptureOnClick(readyState, isCaptured, setIsCaptured, videoRef,
                                                       canvasRef, handleOrientation,
                                                       setCaptureDisabled, animationFrameRef, setIsStreaming,
                                                       setStartCapture, setFile, isStarted);}}
                        disabled={captureDisabled} >
                    {isCaptured ? "Confirm" : "Capture"}
                </Button>
                { isCaptured ?
                    <Button id="retakeHeightButton"
                            variation="primary"
                            onClick={() => {RetakeOnClick(videoRef, setIsCaptured,
                                                          setCaptureDisabled, handleOrientation,
                                                          setStartCapture);}} >
                        Retake
                    </Button>
                :
                    null
                }
            </Flex>
            { ! isCaptured ?
                <>
                    <Text>Select approximate distance from Device (in feets)</Text>
                    <Flex direction="column"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                          gap="0rem" >
                        <SliderField id="distanceSlider"
                                     labelHidden={true}
                                     outerStartComponent={minDistance}
                                     outerEndComponent={maxDistance}
                                     defaultValue={minDistance}
                                     min={minDistance}
                                     max={maxDistance}
                                     step={0.1}
                                     value={distance}
                                     onChange={(value) => { setDistance(value);
                                                            setDistMultiplier(((5 / value) * 238).toFixed(1) / 34);}} />
                        <Text>Distance from device: {distance}'</Text>
                    </Flex>
                </>
            :
                null
            }
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  gap="0rem" >
                {IsOutOfRange ?
                    <Text id="alertHeight">Height is out of standard range!</Text>
                :
                    null
                }
            </Flex>
        </Flex>
    );
};
