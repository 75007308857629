import { useState, useEffect, useReducer } from 'react';
import { Flex, Button, Card, DropZone, Text, ScrollView } from '@aws-amplify/ui-react';
import { BsCpu } from "react-icons/bs";

import { SelectorField } from "./selector";
import { FileSelector } from '../../common/fileSelector';
import { FileStatus } from '../../common/fileStatus';
import { ProgressBar, UpdateState } from './loader'
import { ErrorCard, ShowError } from '../../common/errorCard';
import { ResultCard } from './resultsCard';
import { ChangeVisibilityById, SendRequest, GetPresignedUrl, UploadFile } from '../../common/utils';

import '@aws-amplify/ui-react/styles.css';
import './main.css';

const ENDPOINT = "/installation_verifier_legacy";

function ClearEmpties(obj) {
    for (let key in obj) {
        if (!obj[key] || "object" !== typeof obj[key]) {
            continue;
        }
        if (0 === Object.keys(obj[key]).length) {
            delete obj[key];
        }
    }
    return obj;
}

async function OpenInNewTab(data, setDisableBrowse) {
    let response = await SendRequest({ method: "post",
                                       endpoint: ENDPOINT + "/prettify_json",
                                       data: {"json": ClearEmpties(data)},
                                       headers: {"Content-Type": "Application/json"} });
    if (null !== response && 200 === response.status) {
        var w = window.open("report", "_blank");
        w.document.write(response.data);
    } else {
        ShowError(response.data.msg);
        setDisableBrowse(true);
    }
}

function UpdateFacilityCodes(data, setFacilityCodes, setIsFacilityLoading, setIdObject) {
    let facilityCodes = [];
    setIdObject(data);
    Object.entries(data).map(([facilityCode, ]) => {
        if (data[facilityCode].length) {
            facilityCodes.push({id: facilityCode, label: facilityCode});
        }
        return null;
    })
    facilityCodes.sort((a, b) => a.id.localeCompare(b.id));
    setFacilityCodes(facilityCodes);
    setIsFacilityLoading(false);
}

async function GetFacilityCodes(setFacilityCodes, setIsFacilityLoading, setIdObject, setDisableBrowse) {
    setIsFacilityLoading(true);
    let response = await SendRequest({ method: "get",
                                       endpoint: ENDPOINT + "/get_unique_ids",
                                       headers: {"Content-Type": "Application/json"} });
    if (null !== response && 200 === response.status) {
        UpdateFacilityCodes(response.data, setFacilityCodes, setIsFacilityLoading, setIdObject);
    } else {
        ShowError(response.data.msg);
        setDisableBrowse(true);
    }
}

function ConstructResultsJson(data, setResultsData) {
    let tmp = {
        filesCount: data.length,
        completionPercentage: data.completionPercentage,
        verifiedTag: {},
        unassociatedContext: {},
        duplicateTag: {},
        multipleTagsInImage: {},
        tagInInvalidFormat: {},
        tagWithInvalidCategory: {},
        tagNotFoundInDb: {},
        tagNotFoundInImage: {},
        failure: {}
    };
    for (let result of data) {
        let fileName = result.data.file_name;
        delete result.data.file_name;
        if ("msg" in result.data) {
            tmp.failure[fileName] = result.data.msg;
            continue;
        }
        let deviceStatus = result.data.device_status;
        delete result.data.device_status;
        tmp[deviceStatus][fileName] = result.data;
    }
    setResultsData(tmp);
}

async function ProcessChunk(files, forceUpdate) {
    const responses = files.map(async (file,) => {
        file.state = "upload";
        forceUpdate();
        let response;
        const formData = new FormData();
        formData.append("file_name", file.name);
        formData.append("facility_code", document.getElementById("facilityCodeSelector").value);
        formData.append("project_id", document.getElementById("projectIdSelector").value);
        response = await GetPresignedUrl(formData, ENDPOINT);
        if (200 !== response.status) {
            file.state = "error";
            forceUpdate();
            return response;
        }
        response = await UploadFile(file, response.data.url);
        if (200 !== response.status) {
            file.state = "error";
            forceUpdate();
            return response;
        }
        file.state = "process";
        forceUpdate();
        response = await SendRequest({ method: "post",
                                       endpoint: ENDPOINT + "/process_file",
                                       data: formData });
        if (200 !== response.status) {
            file.state = "error";
            forceUpdate();
            return response;
        }
        file.state = "success";
        forceUpdate();
        return response;
    });
    return await Promise.all(responses);
}

async function ProcessFiles(files, setResultsData, setPercentage, setText, forceUpdate) {
    ChangeVisibilityById("progressbar", "block");
    ChangeVisibilityById("currentStatus", "block");
    let chunkSize = 120;
    var stepInfo = {"previous" : 0, "current" : 0};
    const chunks = files.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/chunkSize)
        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, [])
    var stepSize = 80 / chunks.length;
    var results = [];
    for (let i = 0; i < chunks.length; i++) {
        stepInfo["current"] += stepSize;
        await UpdateState("Processing", 100, stepInfo, setPercentage, setText);
        let result = await ProcessChunk(chunks[i], forceUpdate);
        results = results.concat(result);
    }
    stepInfo["current"] = 90;
    await UpdateState("Retrieving results", 300, stepInfo, setPercentage, setText);
    const formData = new FormData();
    formData.append("facility_code", document.getElementById("facilityCodeSelector").value);
    formData.append("project_id", document.getElementById("projectIdSelector").value);
    let response = await SendRequest({ method: "post",
                                       endpoint: ENDPOINT + "/get_completion_percentage",
                                       data: formData });
    results["completionPercentage"] = 200 === response.status ? response.data.completion_percentage : .0;
    stepInfo["current"] = 100;
    await UpdateState("Done", 100, stepInfo, setPercentage, setText);
    ChangeVisibilityById("progressbar", "none");
    ChangeVisibilityById("currentStatus", "none");
    ConstructResultsJson(results, setResultsData);
    ChangeVisibilityById("resultsCard", "block");
}

function InputField({ setFiles, disableBrowse, setDisableBrowse }) {
    const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    const [compressionPercentage, setCompressionPercentage] = useState(100);
    useEffect(() => {
        ChangeVisibilityById("compressionStatus",
                             100 === compressionPercentage ? "none" : "block");
    }, [compressionPercentage]);
    const OnFileDrop = (files) => {
        let acceptedFiles = files.acceptedFiles;
        if (0 === acceptedFiles.length) {
            return;
        }
        setFiles(acceptedFiles);
    };
    return (
        <DropZone id="dropZone"
                  acceptedFileTypes={acceptedFileTypes}
                  onDropComplete={OnFileDrop}>
            <Flex direction="column"
                  alignItems="center">
                <Text>Drop image containing Device <span id="hintKeyWord">TAG</span> here or</Text>
                <FileSelector setFile={setFiles}
                              disableBrowse={disableBrowse}
                              setDisableBrowse={setDisableBrowse}
                              setCompressionPercentage={setCompressionPercentage}
                              acceptedFileTypes={acceptedFileTypes}
                              isMultiple={true} />
            </Flex>
            <Text id="compressionStatus">Compressing Image: {compressionPercentage}%</Text>
        </DropZone>
    );
}

function UploadCard({ files, setFiles, setPercentage, setText, setDisableToggle, disableBrowse,
                      setDisableBrowse, setResultsData, isProcessing, setIsProcessing }) {
    const [disableProcess, setDisableProcess] = useState(true);
    const [idObject, setIdObject] = useState({});
    const [facilityCode, setFacilityCode] = useState("");
    const [projectId, setProjectId] = useState("");
    const [facilityCodes, setFacilityCodes] = useState([]);
    const [projectIds, setProjectIds] = useState([]);
    const [isFacilityLoading, setIsFacilityLoading] = useState(true);
    const [projectIdStatus, setProjectIdStatus] = useState("Not set");
    const [facilityCodeStatus, setFacilityCodeStatus] = useState("Not set");
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    useEffect(() => {
        GetFacilityCodes(setFacilityCodes, setIsFacilityLoading, setIdObject, setDisableBrowse);
    }, []);
    useEffect(() => {
        let projectIds = [];
        if (facilityCode in idObject) {
            let ids = idObject[facilityCode];
            for (let i in ids) {
                let id = ids[i];
                projectIds.push({id: id, label: id});
            }
        }
        projectIds.sort((a, b) => a.id.localeCompare(b.id));
        setProjectIds(projectIds);
    }, [facilityCode, idObject])
    useEffect(() => {
        let allowed_states = ["Exists"];
        setDisableProcess(! files.length
                          || ! allowed_states.includes(facilityCodeStatus)
                          || ! allowed_states.includes(projectIdStatus));
    }, [files, facilityCodeStatus, projectIdStatus]);
    const processClicked = () => {
        setDisableBrowse(true);
        setDisableProcess(true);
        setDisableToggle(true);
        setIsProcessing(true);
        ProcessFiles(files, setResultsData, setPercentage, setText, forceUpdate);
    };
    return (
        <Card variation="default"
              width={{ base: "90%", large: "30%" }}>
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="1rem" >
                <SelectorField facilityCode={facilityCode}
                               facilityCodes={facilityCodes}
                               isFacilityLoading={isFacilityLoading}
                               setFacilityCode={setFacilityCode}
                               facilityCodeStatus={facilityCodeStatus}
                               setFacilityCodeStatus={setFacilityCodeStatus}
                               projectId={projectId}
                               projectIds={projectIds}
                               setProjectId={setProjectId}
                               projectIdStatus={projectIdStatus}
                               setProjectIdStatus={setProjectIdStatus}
                               isProcessing={isProcessing} />
                    <InputField setFiles={setFiles}
                                disableBrowse={disableBrowse}
                                setDisableBrowse={setDisableBrowse} />
                {files.length ?
                    <ScrollView id="filesView">
                        {files.map((file) => (<Text key={file.name}>
                                                    {file.name}
                                                    <FileStatus state={file.state} />
                                              </Text>))}
                    </ScrollView>
                :
                    null
                }
                <Button id="processButton"
                        onClick={processClicked}
                        isDisabled={disableProcess}
                        variation="primary"
                        colorTheme="success"
                        gap="0.2rem" >
                    <BsCpu />Process
                    </Button>
            </Flex>
        </Card>
    );
}

export const LegacyApp = ({ disableBrowse, setDisableBrowse, setDisableToggle }) => {
    const [files, setFiles] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [text, setText] = useState("");
    const [resultsData, setResultsData] = useState({});
    return (
        <>
            <UploadCard files={files}
                        setFiles={setFiles}
                        setPercentage={setPercentage}
                        setText={setText}
                        setDisableToggle={setDisableToggle}
                        disableBrowse={disableBrowse}
                        setDisableBrowse={setDisableBrowse}
                        setResultsData={setResultsData}
                        isProcessing={isProcessing}
                        setIsProcessing={setIsProcessing} />
            <ProgressBar percentage={percentage}
                         text={text}/>
            <ErrorCard />
            <ResultCard resultsData={resultsData}
                        setFiles={setFiles}
                        OpenInNewTab={OpenInNewTab}
                        setDisableBrowse={setDisableBrowse}
                        setDisableToggle={setDisableToggle}
                        setIsProcessing={setIsProcessing} />
        </>
    );
}
