import { useState, useEffect } from 'react';
import { Flex, Button, Card, Text, ScrollView } from '@aws-amplify/ui-react';
import { BsCpu, BsXOctagonFill, BsSkipForwardFill } from "react-icons/bs";

import { SelectorField } from "./selector";
import { FileSelector } from '../../common/fileSelector';
import { HeightMeasurement, IsOutOfRange } from './heightMeasurement';
import { StatusLine, UpdateState } from '../../common/statusline';
import { ErrorCard, ShowError } from '../../common/errorCard';
import { ConfirmCard, ShowConfirm } from '../../common/confirmCard';
import { ResultCard } from './resultsCard';
import { ChangeVisibilityById, SendRequest, GetPresignedUrl, UploadFile } from '../../common/utils';

import '@aws-amplify/ui-react/styles.css';
import './main.css';

const ENDPOINT = "/installation_verifier_tag";

function ResetResultsData(setResultsData) {
    setResultsData({
                   completionPercentage: 0,
                   deviceId: "unknown",
                   deviceStatus: "not processed",
                   architecturalDrawing: "unknown",
                   otherTags: [],
                   isContext: "no",
                   placementHeight: ""
    });
}

function UpdateFacilityCodes(data, setFacilityCodes, setIsFacilityLoading, setIdObject) {
    let facilityCodes = [];
    setIdObject(data);
    Object.entries(data).map(([facilityCode, ]) => {
        if (data[facilityCode].length) {
            facilityCodes.push({id: facilityCode, label: facilityCode});
        }
        return null;
    })
    facilityCodes.sort((a, b) => a.id.localeCompare(b.id));
    setFacilityCodes(facilityCodes);
    setIsFacilityLoading(false);
}

async function GetFacilityCodes(setFacilityCodes, setIsFacilityLoading, setIdObject, setDisableBrowse) {
    setIsFacilityLoading(true);
    let response = await SendRequest({ method: "get",
                                       endpoint: ENDPOINT + "/get_unique_ids",
                                       headers: {"Content-Type": "Application/json"} });
    if (null !== response && 200 === response.status) {
        UpdateFacilityCodes(response.data, setFacilityCodes, setIsFacilityLoading, setIdObject);
    } else {
        ShowError(response.data.msg);
        setDisableBrowse(true);
    }
}

function PostResponse(response, setStatus) {
    if (200 === response.status) {
        setStatus("success");
    } else {
        setStatus("error");
    }
}

async function ProcessDevice(file, setDeviceStatus) {
    const name = "deviceBadge";
    document.getElementById(name).classList.add("active");
    document.getElementById("contextBadge").classList.remove("active");
    document.getElementById("additionalBadge").classList.remove("active");
    document.getElementById("doneBadge").classList.remove("active");
    let response;
    await UpdateState(name, 25);
    const formData = new FormData();
    formData.append("file_name", file.name);
    formData.append("facility_code", document.getElementById("facilityCodeSelector").value);
    formData.append("project_id", document.getElementById("projectIdSelector").value);
    response = await GetPresignedUrl(formData, ENDPOINT);
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setDeviceStatus);
        return response;
    }

    await UpdateState(name, 50);
    response = await UploadFile(file, response.data.url);
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setDeviceStatus);
        return response;
    }
    await UpdateState(name, 75);
    response = await SendRequest({ method: "post",
                                   endpoint: ENDPOINT + "/process_file",
                                   data: formData });
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setDeviceStatus);
        return response;
    }
    await UpdateState(name, 100);
    PostResponse(response, setDeviceStatus);
    return response;
}

async function ProcessContext(file, setContextStatus, device_id) {
    const name = "contextBadge";
    document.getElementById(name).classList.add("active");
    document.getElementById("deviceBadge").classList.remove("active");
    document.getElementById("additionalBadge").classList.remove("active");
    document.getElementById("doneBadge").classList.remove("active");
    let response;
    await UpdateState(name, 25);
    const formData = new FormData();
    formData.append("file_name", file.name);
    formData.append("facility_code", document.getElementById("facilityCodeSelector").value);
    formData.append("project_id", document.getElementById("projectIdSelector").value);
    response = await GetPresignedUrl(formData, ENDPOINT);
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setContextStatus);
        return response;
    }
    await UpdateState(name, 50);
    response = await UploadFile(file, response.data.url);
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setContextStatus);
        return response;
    }
    await UpdateState(name, 75);
    formData.append("device_id", device_id);
    response = await SendRequest({ method: "post",
                                   endpoint: ENDPOINT + "/process_context",
                                   data: formData });
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setContextStatus);
        return response;
    }
    await UpdateState(name, 100);
    if (response.data["is_context"]) {
        PostResponse(response, setContextStatus);
    } else {
        setContextStatus("error");
    }
    return response;
}

async function ProcessAdditional(file, resultsData, setAdditionalStatus) {
    const name = "additionalBadge";
    document.getElementById(name).classList.add("active");
    document.getElementById("deviceBadge").classList.remove("active");
    document.getElementById("contextBadge").classList.remove("active");
    document.getElementById("doneBadge").classList.remove("active");
    let response;
    await UpdateState(name, 25);
    const formData = new FormData();
    formData.append("file_name", file.name);
    formData.append("facility_code", document.getElementById("facilityCodeSelector").value);
    formData.append("project_id", document.getElementById("projectIdSelector").value);
    response = await GetPresignedUrl(formData, ENDPOINT);
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setAdditionalStatus);
        return response;
    }
    await UpdateState(name, 50);
    response = await UploadFile(file, response.data.url);
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setAdditionalStatus);
        return response;
    }
    await UpdateState(name, 75);
    formData.append("device_id", resultsData.deviceId);
    formData.append("is_out_of_range", IsOutOfRange);
    response = await SendRequest({ method: "post",
                                   endpoint: ENDPOINT + "/set_height",
                                   data: formData });
    if (200 !== response.status) {
        await UpdateState(name, 100);
        PostResponse(response, setAdditionalStatus);
        return response;
    }
    await UpdateState(name, 100);
    PostResponse(response, setAdditionalStatus);
    return response;
}

async function ProcessFile(file, setDeviceStatus, setContextStatus, setAdditionalStatus,
                           setResultsData, resultsData, setDisableBrowse, setFile,
                           setProcessPhase, processPhase) {
    let message;
    let response;
    ChangeVisibilityById("cancelButton", "none");
    ChangeVisibilityById("skipButton", "none");
    ChangeVisibilityById("errorCard", "none");
    switch (processPhase) {
        case "device":
            ChangeVisibilityById("resultsCard", "none");
            response = await ProcessDevice(file, setDeviceStatus);
            resultsData["image"] = file.name;
            if ("device_status" in response.data) {
                resultsData["deviceId"] = response.data.device_id;
                resultsData["deviceStatus"] = response.data.device_status;
                resultsData["architecturalDrawing"] = response.data.architectural_drawing;
                resultsData["otherTags"] = response.data.other;
                resultsData["completionPercentage"] = response.data.completion_percentage;
            }
            if (200 !== response.status) {
                message = response.data.msg;
            } else {
                if (! RegExp("^(CAM|ACD|CR)").test(resultsData.deviceId)) {
                    ChangeVisibilityById("skipButton", "block");
                }
                setProcessPhase("context");
            }
            break;
        case "context":
            response = await ProcessContext(file, setContextStatus, resultsData.deviceId);
            resultsData["context"] = file.name;
            if (200 !== response.status) {
                message = response.data.msg;
            } else {
                if (response.data.is_context) {
                    resultsData["isContext"] = "yes";
                    if (! RegExp("^(CAM|ACD|CR)").test(resultsData.deviceId)) {
                        setAdditionalStatus("info");
                        setProcessPhase("final");
                        break;
                    };
                    setProcessPhase("additional");
                } else {
                    ShowConfirm(setDisableBrowse);
                }
            }
            break;
        case "additional":
            response = await ProcessAdditional(file, resultsData, setAdditionalStatus);
            resultsData["additional"] = file.name;
            resultsData["isOutOfRange"] = IsOutOfRange;
            if (200 !== response.status) {
                message = response.data.msg;
                document.getElementById("doneBadge").classList.remove("active");
            } else {
                setProcessPhase("final");
            }
            break;
        default:
            break;
    }
    if (message) {
        ShowError(message);
        setDisableBrowse(false);
        if ("device" !== processPhase || ("device" === processPhase && resultsData.deviceStatus.startsWith("DUPLICATE"))) {
            ChangeVisibilityById("cancelButton", "block");
        }
    }
    setFile(null);
    setResultsData(resultsData);
}

function HintText({ processPhase }) {
    if (["final", "device"].includes(processPhase)) {
        return (<>containing Device <span id="hintKeyWord">TAG</span></>);
    } else if ("context" === processPhase) {
        return (<>containing Device <span id="hintKeyWord">Context</span></>);
    } else if ("additional" === processPhase) {
        return (<>from the correct distance to calculate the Device <span id="hintKeyWord">Placement Height</span></>);
    }
}

function InputField({ setFile, disableBrowse, setDisableBrowse, processPhase, setStartCapture }) {
    const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    const [compressionPercentage, setCompressionPercentage] = useState(100);
    useEffect(() => {
        ChangeVisibilityById("compressionStatus",
                             100 === compressionPercentage ? "none" : "block");
    }, [compressionPercentage]);
    return (
        <Flex id="captureZone"
              direction="column"
              alignItems="center">
            <Text>Capture image <HintText processPhase={processPhase} /></Text>
            <FileSelector setFile={setFile}
                          disableBrowse={disableBrowse}
                          setDisableBrowse={setDisableBrowse}
                          setCompressionPercentage={setCompressionPercentage}
                          acceptedFileTypes={acceptedFileTypes}
                          isMultiple={false}
                          processPhase={processPhase}
                          setStartCapture={setStartCapture}/>
            <Text id="compressionStatus">Compressing Image: {compressionPercentage}%</Text>
        </Flex>
    );
}

function CancelOnClick(setFile, setDisableBrowse, setDisableProcess) {
    ChangeVisibilityById("cancelButton", "none");
    ChangeVisibilityById("skipButton", "none");
    ChangeVisibilityById("errorCard", "none");
    ChangeVisibilityById("resultsCard", "block");
    setDisableBrowse(true);
    setDisableProcess(true);
    setFile(null);
}

function SkipOnClick(setContextStatus, setProcessPhase, setAdditionalStatus) {
    ChangeVisibilityById("cancelButton", "none");
    ChangeVisibilityById("skipButton", "none");
    ChangeVisibilityById("errorCard", "none");
    setContextStatus("info");
    setAdditionalStatus("info");
    setProcessPhase("final");
}

async function SaveResults(setDisableBrowse, setDisableToggle, resultsData) {
    ChangeVisibilityById("resultsCard", "block");
    setDisableBrowse(true);
    setDisableToggle(false);
    const formData = new FormData();
    formData.append("facility_code", document.getElementById("facilityCodeSelector").value);
    formData.append("project_id", document.getElementById("projectIdSelector").value);
    formData.append("info", JSON.stringify(resultsData));
    let response = await SendRequest({ method: "post",
                                       endpoint: ENDPOINT + "/save_results",
                                       data: formData });
    if (null === response || 200 !== response.status) {
        console.log(response.data.msg);
    }
}

function UploadCard({ file, setFile, setDisableToggle, disableBrowse, setDisableBrowse,
                      processPhase, setProcessPhase, setDeviceStatus, setContextStatus,
                      setAdditionalStatus, setResultsData, resultsData, isProcessing,
                      setIsProcessing, confirmStatus, setConfirmStatus }) {
    const [disableProcess, setDisableProcess] = useState(true);
    const [idObject, setIdObject] = useState({});
    const [facilityCode, setFacilityCode] = useState("");
    const [projectId, setProjectId] = useState("");
    const [facilityCodes, setFacilityCodes] = useState([]);
    const [projectIds, setProjectIds] = useState([]);
    const [isFacilityLoading, setIsFacilityLoading] = useState(true);
    const [projectIdStatus, setProjectIdStatus] = useState("Not set");
    const [facilityCodeStatus, setFacilityCodeStatus] = useState("Not set");
    const [height, setHeight] = useState(0);
    const [startCapture, setStartCapture] = useState(false);
    useEffect(() => {
        GetFacilityCodes(setFacilityCodes, setIsFacilityLoading, setIdObject, setDisableBrowse);
    }, []);
    useEffect(() => {
        let projectIds = [];
        if (facilityCode in idObject) {
            let ids = idObject[facilityCode];
            for (let i in ids) {
                let id = ids[i];
                projectIds.push({id: id, label: id});
            }
        }
        projectIds.sort((a, b) => a.id.localeCompare(b.id));
        setProjectIds(projectIds);
    }, [facilityCode, idObject])
    useEffect(() => {
        let allowed_states = ["Exists"];
        setDisableProcess(null === file
                          || ! allowed_states.includes(facilityCodeStatus)
                          || ! allowed_states.includes(projectIdStatus));
    }, [file, facilityCodeStatus, projectIdStatus]);
    const processClicked = () => {
        setDisableBrowse(true);
        setDisableProcess(true);
        setDisableToggle(true);
        setIsProcessing(true);
        ProcessFile(file, setDeviceStatus, setContextStatus, setAdditionalStatus,
                    setResultsData, resultsData, setDisableBrowse, setFile,
                    setProcessPhase, processPhase);
    };
    useEffect(() => {
        switch (processPhase) {
            case "device":
                ResetResultsData(setResultsData);
                break;
            case "final":
                SaveResults(setDisableBrowse, setDisableToggle, resultsData);
                break;
            default:
                setDisableBrowse(false);
                setDisableToggle(false);
                break;
        }
    }, [processPhase]);
    useEffect(() => {
        switch (confirmStatus) {
            case 1:
                resultsData["isContext"] = "yes";
                resultsData["isContextForced"] = true;
                setProcessPhase("additional");
                setContextStatus("success");
                break;
            case 2:
                ChangeVisibilityById("cancelButton", "block");
                break;
            default:
                break;
        }
        setConfirmStatus(0);
    }, [confirmStatus]);
    return (
        <Card variation="default"
              width={{ base: "90%", large: "22%" }}>
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="1rem">
                <SelectorField facilityCode={facilityCode}
                               facilityCodes={facilityCodes}
                               isFacilityLoading={isFacilityLoading}
                               setFacilityCode={setFacilityCode}
                               facilityCodeStatus={facilityCodeStatus}
                               setFacilityCodeStatus={setFacilityCodeStatus}
                               projectId={projectId}
                               projectIds={projectIds}
                               setProjectId={setProjectId}
                               projectIdStatus={projectIdStatus}
                               setProjectIdStatus={setProjectIdStatus}
                               isProcessing={isProcessing} />
                <InputField setFile={setFile}
                            disableBrowse={disableBrowse}
                            setDisableBrowse={setDisableBrowse}
                            processPhase={processPhase}
                            setStartCapture={setStartCapture} />
                { null !== file ?
                    <ScrollView id="filesView">
                        <Text key={file.name}>{file.name}</Text>
                    </ScrollView>
                :
                    null
                }
                { "additional" === processPhase ?
                    startCapture ?
                        <HeightMeasurement height={height}
                                           setHeight={setHeight}
                                           resultsData={resultsData}
                                           startCapture={startCapture}
                                           setStartCapture={setStartCapture}
                                           setFile={setFile} />
                    :
                        0 !== height ?
                            <Text>Device placement Height: {height}"</Text>
                        :
                            null
                :
                    null
                }
                <Flex direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem">
                    <Button id="processButton"
                            onClick={processClicked}
                            isDisabled={disableProcess}
                            variation="primary"
                            colorTheme="success"
                            gap="0.2rem" >
                        <BsCpu />Process
                    </Button>
                    <Button id="skipButton"
                            onClick={() => { SkipOnClick(setContextStatus, setProcessPhase, setAdditionalStatus);}}
                            variation="primary"
                            colorTheme="info"
                            gap="0.2rem" >
                        <BsSkipForwardFill /> Skip
                    </Button>
                    <Button id="cancelButton"
                            onClick={() => { CancelOnClick(setFile, setDisableBrowse, setDisableProcess) }}
                            variation="primary"
                            colorTheme="error"
                            gap="0.2rem" >
                        <BsXOctagonFill /> Cancel
                    </Button>
                </Flex>
            </Flex>
        </Card>
    );
}

export const NewApp = ({ disableBrowse, setDisableBrowse, setDisableToggle }) => {
    const [file, setFile] = useState(null);
    const [deviceStatus, setDeviceStatus] = useState("default");
    const [contextStatus, setContextStatus] = useState("default");
    const [additionalStatus, setAdditionalStatus] = useState("default");
    const [doneStatus, setDoneStatus] = useState("defualt");
    const [processPhase, setProcessPhase] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [resultsData, setResultsData] = useState({});
    const [confirmStatus, setConfirmStatus] = useState(0);
    useEffect(() => {
        ResetResultsData(setResultsData);
        setProcessPhase("device");
    }, []);
    return (
        <>
            <UploadCard file={file}
                        setFile={setFile}
                        setDisableToggle={setDisableToggle}
                        disableBrowse={disableBrowse}
                        setDisableBrowse={setDisableBrowse}
                        processPhase={processPhase}
                        setProcessPhase={setProcessPhase}
                        setDeviceStatus={setDeviceStatus}
                        setContextStatus={setContextStatus}
                        setAdditionalStatus={setAdditionalStatus}
                        setResultsData={setResultsData}
                        resultsData={resultsData}
                        isProcessing={isProcessing}
                        setIsProcessing={setIsProcessing}
                        confirmStatus={confirmStatus}
                        setConfirmStatus={setConfirmStatus} />
            <StatusLine mode="tag"
                        deviceStatus={deviceStatus}
                        contextStatus={contextStatus}
                        additionalStatus={additionalStatus}
                        doneStatus={doneStatus}
                        setDoneStatus={setDoneStatus} />
            <ConfirmCard setDisableBrowse={setDisableBrowse}
                         setDisableToggle={setDisableToggle}
                         setConfirmStatus={setConfirmStatus} />
            <ErrorCard />
            <ResultCard resultsData={resultsData}
                        doneStatus={doneStatus}
                        setFile={setFile}
                        setDisableBrowse={setDisableBrowse}
                        setDisableToggle={setDisableToggle}
                        setProcessPhase={setProcessPhase}
                        setDeviceStatus={setDeviceStatus}
                        setContextStatus={setContextStatus}
                        setAdditionalStatus={setAdditionalStatus}
                        setIsProcessing={setIsProcessing} />
        </>
    );
}
