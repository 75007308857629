import { useState, useEffect } from 'react';
import { Flex, Text, SliderField } from '@aws-amplify/ui-react';

import './heightSlider.css';

export let IsOutOfRange = 0;

function UpdateHeightLimit(resultsData, setMinHeight, setMaxHeight, setDefaultHeight, setHeight,
                           setThreshold) {
    let tag = resultsData.deviceId;
    let min = 96;
    let max = 180;
    let threshold = 120;
    if (tag.startsWith("ACD")) {
        min = 36;
        max = 46;
        threshold = 40;
    }
    let avg = Math.floor((min+max)/2);
    setMinHeight(min);
    setMaxHeight(max);
    setDefaultHeight(avg);
    setHeight(avg);
    setThreshold(threshold);
    resultsData["placementHeight"] = avg + "\"";
    IsOutOfRange = 0;
};

export const HeightSlider = ({ height, disableField, setHeight, resultsData }) => {
    const [minHeight, setMinHeight] = useState(96);
    const [maxHeight, setMaxHeight] = useState(180);
    const [defaultHeight, setDefaultHeight] = useState(minHeight);
    const [threshold, setThreshold] = useState(120);
    useEffect(() => {
        UpdateHeightLimit(resultsData, setMinHeight, setMaxHeight, setDefaultHeight, setHeight,
                          setThreshold);
    }, [resultsData]);
    const UpdateValue = (value) => {
        setHeight(value);
        resultsData["placementHeight"] = value + "\"";
        let slider = document.getElementById("heightSlider");
        IsOutOfRange = (value < threshold) | 0
        if (IsOutOfRange) {
            slider.classList.add("error");
        } else {
            slider.classList.remove("error");
        }
    };
    return (
        <Flex direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="0rem" >
            <Text>Device placement height(in inches): {height}</Text>
            <SliderField id="heightSlider"
                         labelHidden={true}
                         outerStartComponent={minHeight}
                         outerEndComponent={maxHeight}
                         defaultValue={defaultHeight}
                         min={minHeight}
                         max={maxHeight}
                         value={height}
                         isDisabled={disableField}
                         onChange={UpdateValue} />
            { IsOutOfRange ?
                <Text id="alertHeight">Height is out of standard range!</Text>
            :
                null
            }
        </Flex>
    )
}
