import { Flex, Card, Text } from '@aws-amplify/ui-react';

import { ChangeVisibilityById } from './utils';

import '@aws-amplify/ui-react/styles.css';
import './errorCard.css';

export const ShowError = (message) => {
    document.getElementById("errorText").innerHTML = message;
    ChangeVisibilityById("errorCard", "block");
}

export const ErrorCard = ({ message }) => {
    return (
        <Card id="errorCard"
              variation="default"
              width={{ base: "90%", large: "fit-content" }}>
            <Flex direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center" >
                <Text id="errorText">{message}</Text>
            </Flex>
        </Card>
    );
}
