import axios from 'axios';

export const URL = "https://rntmzhnaibvrt6xztigmo3rwiq0qvdjh.lambda-url.us-east-2.on.aws";

export const Sleep = ms => new Promise(r => setTimeout(r, ms));
export let IsMobile;

export const UUIDV4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                                                          (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}

export const SetMobile = (value) => {
    IsMobile = value;
}

export const IsObjectEmpty = (data) => {
    return 0 === Object.keys(data).length;
}

export const ChangeVisibilityById = (name, state) => {
    document.getElementById(name).style.setProperty("display", state);
}

export async function SendRequest({ url="", method="get", endpoint="", headers={}, data={} } = {}) {
    let request = {};
    request.method = method;
    request.baseURL = url === "" ? URL : url;
    request.timeout = 60*1000;
    if (endpoint) {
        request.url = endpoint;
    }
    if (data) {
        request.data = data;
    }
    if (headers) {
        request.headers = headers;
    }
    let response = null;
    let message = null;
    try {
        response = await axios(request);
    } catch(error) {
        if ("response" in error) {
            if (400 === error.response.status) {
                response = error.response;
            } else if (500 === error.response.status) {
                response = error.response;
                console.log(error.response.data.log_name);
            } else {
                response = {
                    status: error.response.status,
                    data: {
                        msg: error.response.data
                    }
                }
            }
        } else {
            message = "Failed to process request: Got error while trying to process the request.";
            if ("ERR_NETWORK" === error.code) {
                message = "Network connection error: Please check your network connection."
            } else if ("ECONNABORTED" === error.code) {
                message = "Bad connection detected: Please use faster(>2Mbps) connection and try again.";
            }
            response = {
                status: 404,
                data: {
                    msg: message
                }
            }
        }
    }
    return response;
}

export async function GetPresignedUrl(formData, endpoint) {
    let response = await SendRequest({ method: "post",
                                       endpoint: endpoint + "/get_presigned_url",
                                       data: formData });
    return response;
}

export async function UploadFile(file, url) {
    let response = await SendRequest({ url: url,
                                       method: "put",
                                       data: file,
                                       headers: {"Content-Type": file.type} });
    return response;
}
