import { LegacyApp } from './legacy/main';
import { NewApp } from './new/main';
import { IsMobile } from '../common/utils';

import '@aws-amplify/ui-react/styles.css';
import './main.css';

export const TagApp = ({ disableBrowse, setDisableBrowse, setDisableToggle }) => {
    return (
        <>
            { IsMobile ?
                <NewApp disableBrowse={disableBrowse}
                        setDisableBrowse={setDisableBrowse}
                        setDisableToggle={setDisableToggle} />
            :
                <LegacyApp disableBrowse={disableBrowse}
                           setDisableBrowse={setDisableBrowse}
                           setDisableToggle={setDisableToggle} />
            }
        </>
    );
}
